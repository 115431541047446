@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif !important;
  width: 100vw;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p {
  line-height: 25px;
  font-size: 15px;
  color: #525560;
}

.heading h3 {
  font-weight: 600;
  color: #222;
  font-size: 13px;
  letter-spacing: 1px;
  position: relative;
  width: fit-content;
}

.heading h3 span {
  top: 19px;
  background-color: #282828;
  width: 76px;
  height: 2px;
  display: block;
  position: absolute;
  right: -88px;
}

.heading h5 {
  color: #282828;
  font-size: 28px;
  margin-bottom: 15px;
  line-height: 40px;
}

.heading h4 {
  color: #282828;
  font-size: 32px;
  margin-bottom: 15px;
  line-height: 40px;
}

button.btn.btn-light {
  border-radius: 3px;
  border: 0;
  font-size: 18px;
  padding: 9px 35px;
  font-weight: 400;
}

button.btn.btn-info {
  background: #94d0c4;
  border-radius: 3px;
  border: 0;
  font-size: 18px;
  padding: 9px 35px;
  font-weight: 400;
}

img {
  max-width: 100%;
}

.top-header {
  padding: 15px 0px;
  border-bottom: 1px solid #a7a5a5;
}

.top-header .nav-links li {
  margin-right: 39px;
  list-style: none;
  font-size: 15px;
  font-weight: 500;
}
.top-header .nav-links li a {
  color: #696969;
}

button.btn.btn-dark {
  border-radius: 3px;
  background-color: #373737;
  border: 0;
  font-size: 15px;
  padding: 7px 32px;
  font-weight: 400;
}

.banner-section .slick-dots {
  bottom: 19px !important;
}

.banner-section .slick-dots li button:before {
  color: #fff !important;
}

.banner-section .banner-img-inner {
  position: relative;
}

.banner-section .banner-content {
  z-index: 111;
  position: absolute;
  top: 150px;
  font-size: 100px;
  color: #fff;
  padding-left: 125px;
  width: 100%;
}

.banner-section .banner-content h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 57px;
}

.banner-section .banner-content h2 span {
  font-size: 65px;
  font-weight: 600;
}

.about-section {
  padding: 70px 0;
}

.what-we-section {
  background-color: #eafffb;
  padding: 40px 0;
}

.what-we-section .semi-inner {
  padding: 25px 60px;
}

.what-we-section .service-box {
  display: flex;
  margin-bottom: 30px;
}

.what-we-section .service-box .icon {
  width: 50px;
}

.campins-section {
  padding: 60px 0;
}

.products-section {
  background: #000;
  padding: 60px 0;
}

.products-section .products-box {
  background: #fff;
  border-radius: 5px;
}

.products-section .products-box h3 {
  font-size: 22px;
  color: #000;
}

.products-section .products-box p {
  font-size: 15px;
  color: #000;
  line-height: 23px;
}

.products-section .products-box img {
  border-radius: 5px 5px 0 0;
}

.products-section .products-box .product-content {
  width: 72%;
}

.products-section .products-box a {
  background-color: #000;
  color: #fff;
  width: 45px;
  display: block;
  height: 45px;
  text-align: center;
  border-radius: 50px;
  padding: 12px;
}

.event-section {
  padding: 70px 0;
}

.event-section .event-box {
  border-radius: 10px;
  background: #8cd6c7;
  color: #303030;
  padding: 16px 16px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.event-section .event-box a {
  background-color: #fff;
  color: #000;
  width: 45px;
  display: block;
  height: 45px;
  text-align: center;
  border-radius: 50px;
  padding: 12px;
}

.event-section .event-box h6 {
  font-size: 30px;
}

.event-section .event-box h6 span {
  font-size: 12px;
}

.event-section .event-box p {
  color: #000;
}

.event-section .event-box h3 {
  font-size: 20px;
  line-height: 30px;
}

.event-section .event-body {
  height: 445px;
  overflow-y: scroll;
}

.event-section .event-body::-webkit-scrollbar {
  width: 10px;
  margin-left: 10px;
}

.event-section .event-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.event-section .event-body::-webkit-scrollbar-thumb {
  background: #8cd6c7;
  border-radius: 10px;
}

.event-section .event-body::-webkit-scrollbar-thumb:hover {
  background: #8cd6c7;
}

.footer {
  background-color: #0b0706;
  padding: 60px 0;
  color: #fff;
}

.footer .footer-links ul li {
  list-style: none;
}

.footer .footer-links ul li a {
  color: #fff;
  font-size: 14px;
  margin: 20px 0;
  display: block;
}

.mission-section {
  background-color: #eafffb;
  padding: 40px 0;
  margin-top: 14%;
}

.mission-section .mission-img {
  margin-top: -22%;
}

.award-section {
  padding: 60px 0;
}

.award-section .award-box {
  text-align: center;
}

.award-section .award-box h6 {
  font-size: 24px;
  color: #282828;
  margin-top: 16px;
}
.award-section .award-box h5 {
  margin: 10px 0;
  font-size: 17px;
  color: #282828;
}
.award-section .award-box p {
  font-size: 12px;
  font-weight: 600;
  color: #979797;
  line-height: 20px;
}

.team-section {
  background-color: #eafffb;
  padding: 60px 0;
  text-align: center;
}

.team-section h4 {
  color: #2c2c2c;
  font-size: 17px;
  margin: 8px 0px;
  font-weight: 500;
}

.whats-we-do-section {
  padding: 60px 0;
}

.product-section-new {
  background-color: #eafffb;
  padding: 40px 0;
}

.product-box-sections {
  padding: 50px 0;
}

.product-box-sections .products-box-inner {
  border: 1px solid #bfbfbf;
  border-radius: 20px;
  padding: 29px 22px;
  margin-bottom: 20px;
}

.campins-box {
  position: relative;
}

.campins-box img {
  border-radius: 15px;
  position: relative;
}

.campins-box .campins-box-content {
  position: absolute;
  top: 0;
  z-index: 11111;
  color: #fff;
  height: 100%;
  padding: 40px 40px;
}

.campins-box .campins-box-content h4 {
  font-size: 24px;
}
.campins-box .campins-box-content p {
  color: #fff;
  line-height: 23px;
  font-size: 15px;
  margin: 12px 0px 33px 0px;
}

.contact-sections {
  background-color: #eafffb;
  padding: 40px 0;
}

.contact-sections-2 {
  padding: 40px 0;
}

textarea {
  padding: 10px 10px;
  border: 1px solid #dee2e6;
  width: 100%;
  border-radius: 6px;
  height: 114px;
}

.rs-drawer-left.rs-drawer-sm,
.rs-drawer-right.rs-drawer-sm {
  width: 317px !important;
}

.our-product-sections {
  padding: 40px 0;
}

.iti-section {
  width: 90%;
  margin: auto;
}

.event-banner {
  background-color: #eafffb;
  padding: 60px 0;
  text-align: center;
}

.event-banner h4 {
  font-size: 48px !important;
}

.event-banner p {
  color: #000;
}

.event-banner p span {
  margin-right: 10px;
}

.top-header-mobile {
  padding: 12px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-header-mobile .mobile-nav-btn {
  display: flex;
  background: black;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 8px;
}
.rs-drawer-header .rs-drawer-header-close {
  color: #fff;
  left: 263px !important;
  position: absolute;
  top: 33px !important;
  background: #000;
  padding: 6px;
}
.rs-drawer-body {
  padding: 19px 24px !important;
}
.rs-drawer-body ul li {
  list-style: none !important;
  margin-bottom: 37px;
}
.rs-drawer-body ul li a {
  color: #000 !important;
  display: block;
}
.rs-drawer-header {
  padding: 20px 40px 20px 20px !important;
}

.slick-next {
  display: none !important;
}

.mobile {
  display: none;
}

/* Responsive */

@media only screen and (max-width: 600px) {
  .mobile {
    display: block !important;
  }
  button.btn.btn-light {
    font-size: 14px;
    padding: 7px 21px;
  }

  .banner-section .banner-content {
    top: 0px;
    padding-left: 6px;
    padding: 25px 15px;
  }
  .banner-section .banner-content button.btn.btn-light {
    margin-bottom: 84px;
  }
  .banner-section .banner-content h2 {
    font-size: 20px;
    line-height: 25px;
  }
  .banner-section .banner-content h2 span {
    font-size: 20px;
  }
  .about-section {
    padding: 30px 0;
  }
  .what-we-section .semi-inner {
    padding: 25px 0px;
  }
  .campins-section {
    padding: 30px 0;
  }
  .products-section {
    padding: 30px 0;
  }
  .event-section {
    padding: 30px 0;
  }
  .heading h4 {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 7px;
  }
  .event-inner {
    margin-top: 15px;
  }
  .about-section button.btn.btn-info {
    margin-top: 20px !important;
    margin-bottom: 50px !important;
  }
  .event-section .event-box h6 {
    font-size: 22px;
  }
  .event-section .event-box h3 {
    font-size: 17px;
  }
  .footer {
    padding: 30px 0;
  }
  .footer .col-md-6.d-flex.justify-content-end {
    justify-content: flex-start !important;
    margin-top: 15px;
  }
  button.btn.btn-info {
    font-size: 14px;
    padding: 7px 21px;
  }
  .award-section {
    padding: 30px 0;
  }
  .award-section .award-box {
    margin-bottom: 30px;
  }
  .team-section {
    padding: 30px 0;
  }
  .event-banner h4 {
    text-align: left !important;
    font-size: 25px !important;
  }
  .event-banner .row {
    padding-left: 0 !important;
    margin-top: 20px !important;
  }
  .event-banner p{
    margin-bottom: 10px;
  }
  .product-box-sections .products-box-inner img{
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1025px) {
  .banner-section .banner-content {
    top: 95px;
    padding-left: 45px;
}
.campins-box .campins-box-content p{
  margin-bottom: 0;
}
.campins-box .campins-box-content button.btn.btn-light{
  margin-top: 10px !important;
}
.products-section .products-box h3{
  font-size: 20px;
}
.campins-box .campins-box-content {

  padding: 18px 40px;
}
}
